import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = ['form']

  // search() {
  //   clearTimeout(this.timeout)
  //   this.timeout = setTimeout(() => {
  //     Rails.fire(this.formTarget, 'submit')
  //   }, 300)
  // }

  // initialize() {
  //   this.submit = debounce(this.submit.bind(this), 200)
  // }

  submit() {
    // this.submitTarget.click()
    debounce(Rails.fire(this.formTarget, 'submit'), 300)
  }
}
