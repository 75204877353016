import { Controller } from '@hotwired/stimulus'
import Combobox from '@github/combobox-nav'

export default class extends Controller {
  static targets = ['input', 'list', 'value', 'option', 'selected', 'selectField']

  listTargetConnected() {
    this.start()
  }

  connect() {
    // console.log("CONNECTED")
    // console.log(this.data.get('url'))
  }

  stop() {
    this.combobox?.stop()
  }

  disconnect() {
    this.combobox?.destroy()
  }

  start() {
    this.combobox?.destroy()

    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
    // console.log(this.optionTarget.dataset.bgId)
  }

  select(event) {
    // console.log("SELECT")
    // console.log(event)
    // console.log(event.currentTarget)
    // console.log(event.currentTarget.dataset.bgId)
    if (this.data.get('link')) {
      window.location.href = this.data.get('link') + '?q=' + event.currentTarget.dataset.bgId
    } else {
      this.inputTarget.hidden = true
      this.selectFieldTarget.value = event.currentTarget.dataset.bgId
      event.currentTarget.dataset.action = "click->select-search-box#remove"
      this.selectedTarget.insertAdjacentElement("beforeend", event.currentTarget)
      this.listTarget.hidden = true
    }
  }

  remove(event) {
    // console.log("REMOVE")
    // console.log(event)
    // console.log(this.optionTarget.dataset.bgId)
    // console.log(this.optionTarget)
    this.inputTarget.hidden = false
    // console.log("DDDDDDDDDD")
    // console.log(this.selectedTarget.children)
    this.optionTarget.remove()
    this.selectFieldTarget.value = null
  }

  search() {
    // console.log("SEARCH")
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      fetch(this.data.get('url') + '?q=' + this.inputTarget.value).then((response) => {
      response.text().then((html) => {
        document.body.insertAdjacentHTML("beforeend", html);
      })
    })
    }, 300)
  }

  close() {
    this.listTarget.hidden = true
  }
}
