import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['input', 'background', 'complexity', 'red', 'gray']

  connect() {
    document.documentElement.style.overflow = 'hidden'
    document.body.scroll = 'no'
  }

  disconnect() {
    document.documentElement.style.overflow = 'auto'
    document.body.scroll = 'yes'
  }

  toggle(e) {
    const label = e.target.nextElementSibling
    if (e.target.checked) {
      label.classList.remove('text-gray-300')
      label.classList.add('text-purple-600')
    } else {
      label.classList.remove('text-purple-600')
      label.classList.add('text-gray-300')
    }
  }

  complexityToggle(e) {
    this.grayTargets.forEach((el) => {
      el.classList.remove('hidden')
    })
    this.redTargets.forEach((el) => {
      el.classList.add('hidden')
    })
    e.currentTarget.firstElementChild.classList.remove('hidden')
    e.currentTarget.children[1].classList.add('hidden')
  }

}
