import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['bannerId']

  connect() {
    useIntersection(this)
  }

  appear(entry, observer) {
    // console.log('I am visible!')
    // console.log(entry.target.dataset.banner)
    // console.log(entry)
    // console.log(observer)
    fetch('/api/banner_trackings/' + entry.target.dataset.banner + '?new=1', {method: 'PUT'}).then((response) => {
    })
    // callback automatically triggered when the element
    // intersects with the viewport (or root Element specified in the options)
    observer.unobserve(entry.target)
  }

  disappear(entry, observer) {
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
  }
}
