import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "slide" ]

  initialize() {
    this.index = 0
    this.showCurrentSlide()
  }

  runSlides() {
    setTimeout(() => {
      this.index = this.index + 1 >= Number(this.element.dataset.maxIndex) ? 0 : this.index + 1
      this.showCurrentSlide()
    }, 10000)
  }


  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index !== this.index
    })
    this.runSlides()
  }
}
