import { Controller } from '@hotwired/stimulus'
import Combobox from '@github/combobox-nav'

export default class extends Controller {
  static targets = ['input', 'list', 'value', 'link']

  listTargetConnected() {
    this.start()
  }

  stop() {
    this.combobox?.stop()
  }

  disconnect() {
    this.combobox?.destroy()
  }

  start() {
    this.combobox?.destroy()

    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
    this.valueTarget.innerHTML = this.inputTarget.value
    this.linkTarget.href = "/szukaj?q=" + this.inputTarget.value
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 200)
  }

  close() {
    this.listTarget.hidden = true
  }
}
