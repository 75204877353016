import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['search', 'recommendation', 'searchBody', 'recommendationBody']

  tab(event) {
    event.preventDefault()
    console.log(event.currentTarget)

    if (event.currentTarget === this.searchTarget) {
      this.searchTarget.classList.add('bg-purple-600', 'text-white', 'rounded-t-3xl')
      this.searchTarget.classList.remove('bg-purple-100', 'text-purple-600')
      this.recommendationTarget.classList.add('bg-purple-100', 'text-purple-600')
      this.recommendationTarget.classList.remove('bg-purple-600', 'text-white', 'rounded-t-3xl')
      this.searchBodyTarget.classList.remove('hidden')
      this.recommendationBodyTarget.classList.add('hidden')
    }

    if (event.currentTarget === this.recommendationTarget) {
      this.searchTarget.classList.remove('bg-purple-600', 'text-white', 'rounded-t-3xl')
      this.searchTarget.classList.add('bg-purple-100', 'text-purple-600')
      this.recommendationTarget.classList.remove('bg-purple-100', 'text-purple-600')
      this.recommendationTarget.classList.add('bg-purple-600', 'text-white', 'rounded-t-3xl')
      this.searchBodyTarget.classList.add('hidden')
      this.recommendationBodyTarget.classList.remove('hidden')
    }
  }
}
