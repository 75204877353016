import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "page" ]

  search() {
    clearTimeout(this.timeout)
    this.pageTarget.value = 1
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 300)
  }
}
