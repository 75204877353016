import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['content', 'background']

  close(e) {
    // Prevent default action
    e.preventDefault();
    // Remove from parent
    const modal = document.getElementById("modal_new");
    modal.innerHTML = "";

    // Remove the src attribute from the modal
    modal.removeAttribute("src");

    // Remove complete attribute
    modal.removeAttribute("complete");
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) {
      this.close(e);
    }
  }
}
