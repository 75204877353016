import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';

export default class extends Controller {
  static targets = [ "slide" ]

  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: "#gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
      paddingFn: (viewportSize) => {
        return {
          top: 30, bottom: 30, left: 20, right: 20
        }
      },
      showHideAnimationType: 'fade',
    });

    const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
      // Plugins options, for example:
      type: 'auto',
    });

    lightbox.init();
  }



}
