import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "field" ]

  initialize() {

  }

  toggle() {
    this.fieldTarget.classList.toggle('hidden')
  }


}
